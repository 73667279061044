import React from 'react';

import { Currencies } from '../../types';

import { NumberHelper } from '../NumberHelper';

interface MoneyHelperProps {
  value: number;
  currency?: Currencies;
  useSuffix?: boolean;
}

function MoneyHelper({
  value,
  currency = Currencies.USD,
  useSuffix = false
}: MoneyHelperProps) {
  if (useSuffix) {
    return <NumberHelper value={value} suffix={currency} />;
  }

  return (
    <NumberHelper
      value={value}
      prefix={currency === Currencies.USD ? '$' : currency}
    />
  );
}

export default MoneyHelper;
