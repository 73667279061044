import React, { Fragment, memo } from 'react';
import upperFirst from 'lodash/upperFirst';
import cl from 'classnames';

import { UserLink } from '../../../../common/helpers/UserLink';

import { Translate } from '../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';

import { invoicesKeys, words } from '../../../../../locales/keys';

import { BillCardItem } from './BillCard.types';
import { BillAccounts, BillStatuses } from '../../../billsTypes';

interface BillCardProps {
  bill: BillCardItem;
  withoutTitle?: boolean;
}

function BillCard({ bill, withoutTitle }: BillCardProps) {
  return (
    <Fragment>
      {withoutTitle ? null : (
        <div className="mb-1 px-2">
          <h6 className="text-lg font-semibold text-center">
            <Translate id={invoicesKeys.singular} /> #{bill.id}
          </h6>
        </div>
      )}
      <div className="mb-4 px-2">
        <div className="flex justify-center leading-snug mb-4 sm:text-md text-sm">
          {bill.body}
        </div>
      </div>
      <div className="flex justify-center mt-3 mb-6">
        <div
          className={cl(
            'text-center py-3 px-6 rounded-lg bg-opacity-20 w-72 max-w-full',
            bill.status === BillStatuses.CANCELED
              ? 'bg-gray-400'
              : 'bg-blue-500'
          )}
        >
          <div className="text-xs text-gray-500 mb-1">
            <Translate id={words.amountDue} />
          </div>
          <div className="text-2xl">
            <MoneyHelper
              useSuffix
              value={bill.amount}
              currency={bill.currency}
            />
          </div>
        </div>
      </div>
      <div className="px-4 space-y-2 mb-6">
        <div className="flex justify-center space-x-4">
          <div className="w-40">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={invoicesKeys.date} />
            </div>
            <div className="text-sm">
              <DateTimeHelper date={bill.createdAt} />
            </div>
          </div>
          <div className="w-24">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={invoicesKeys.id} />
            </div>
            <div className="text-sm">{bill.id}</div>
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          {bill.payer ? (
            <div className="w-40">
              <div className="text-xs text-gray-500 mb-0.5">
                <Translate id={words.payer} />
              </div>
              <div className="text-sm">
                <UserLink user={bill.payer} text={bill.payer.fullName} />
              </div>
            </div>
          ) : null}
          <div className="w-24">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={words.status} />
            </div>
            <div className="text-sm">{upperFirst(bill.status)}</div>
          </div>
        </div>
      </div>
      {bill.account === BillAccounts.PAYONEER && (
        <div className="px-4 space-y-2 mb-6">
          <div className="text-xs text-gray-500 mb-0.5">
            <Translate id={invoicesKeys.messages.payoneerWarning} />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default memo<BillCardProps>(BillCard);
