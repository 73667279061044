import { gql } from 'graphql-request';

import {
  BillID,
  BillUUID,
  BillAmount,
  BillCurrency,
  BillPaymentForm,
  BillPaymentUrl,
  BillBody,
  BillStatus,
  BillCreatedAt,
  BillPayerUUID,
  BillPayerFullName,
  BillPayerPayoneerCheckoutAddress,
  BillPayable,
  BillPayment,
  BillType,
  BillAccount,
  BillPayerNanoID,
  BillProjectID,
  BillProjectUUID,
  BillProjectNanoID,
  BillProjectName,
  BillProjectCreatedAt,
  BillPayerImageUUID,
  BillPayerImageFile,
  BillPaymentData
} from '../billsTypes';

export interface FetchBillQueryResponse {
  id: BillID;
  uuid: BillUUID;
  account: BillAccount;
  amount: BillAmount;
  currency: BillCurrency;
  body: BillBody;
  status: BillStatus;
  createdAt: BillCreatedAt;
  payable: BillPayable;
  payer: {
    nanoId: BillPayerNanoID;
    uuid: BillPayerUUID;
    fullName: BillPayerFullName;
    payoneerCheckoutAddress: BillPayerPayoneerCheckoutAddress;
    image: {
      uuid: BillPayerImageUUID;
      file: BillPayerImageFile;
    };
  };
  project: {
    id: BillProjectID;
    uuid: BillProjectUUID;
    nanoId: BillProjectNanoID;
    name: BillProjectName;
    createdAt: BillProjectCreatedAt;
  };
  paymentForm: BillPaymentForm;
  paymentUrl: BillPaymentUrl;
  paymentData: BillPaymentData;
  payment: BillPayment;
  type: BillType;
}

export const FETCH_BILL_QUERY = gql`
  query Bill($uuid: ID!) {
    bill(uuid: $uuid) {
      id
      uuid
      amount
      currency
      account
      currency
      body
      status
      createdAt
      payable
      paymentForm
      paymentUrl
      paymentData
      type
      payment
      payer {
        uuid
        nanoId
        fullName
        payoneerCheckoutAddress
        image {
          uuid
          file
        }
      }
      project {
        id
        uuid
        nanoId
        name
        createdAt
      }
    }
  }
`;
